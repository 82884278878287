import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { CircularProgress, Container, Typography } from '@mui/material';

// APIs
import { getPractice, getPracticePimsStatus } from '../../../api';

// Components
import { BackToButton, Button, LineItem } from '@lexcelon/react-util';

import { setError } from '../../../alerts';
import { DateTime } from 'luxon';

//Constants
const red = '#d32f2f';

class Practice extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      /** @type {import('@parasightsysteminc/companion-react').Practice | null} */
      practice: null,
      /** @type {import('../../../../../companion-portal-server/routes/practice/pims').PimsIntegrationStatus | null} */
      pimsIntegrationStatus: null
    };
  }

  componentDidMount() {
    this.retrievePractice();
  }

  retrievePractice = async () => {
    // Retrieve the practice id from the url
    this.setState({ isLoading: true });
    try {
      const practice = await getPractice();
      const pimsIntegrationStatus = await getPracticePimsStatus();
      this.setState({ pimsIntegrationStatus, practice });
    }
    catch (error) {
      setError(error ?? 'Error: Unable to retrieve practice info. Please try again.');
    }
    finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    var isSuspended = this.state.practice?.getIsSuspended();
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>{this.state.practice?.getName()}</Typography>
        <Typography variant='h6' style={{ textAlign: 'center', marginBottom: '0.5em', color: isSuspended ? red : 'green' }}>{isSuspended ? 'SUSPENDED' : ('ACTIVE')}</Typography>

        {this.state.isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Practice Info</Typography>
            <>
              <LineItem
                value={this.state.practice?.getIdentifier()}
                description='Identifier'
              />

              <LineItem
                value={this.state.practice?.getName()}
                description='Name'
              />

              <Button component={Link} to={{ pathname: '/instruments', state: { backTo: { pathname: this.props.location, description: 'My Practice' } } }}>View All Instruments</Button>
            </>

            <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1em' }}>Contact Info</Typography>
            <>
              <LineItem
                value={this.state.practice?.getContactName()}
                description='Contact Name'
              />

              <LineItem
                value={this.state.practice?.getContactEmail()}
                description='Contact Email'
              />

              <LineItem
                value={this.state.practice?.getContactPhoneNumber()}
                description='Contact Phone Number'
              />

            </>

            <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1em' }}>Practice Location</Typography>
            <>
              <LineItem
                value={this.state.practice?.getAddress()?.getFullAddress()}
                style={{ marginTop: '0' }}
                description='Address'
              />

              <LineItem
                value={this.state.practice?.getAddress()?.getTimeZone()}
                description='Timezone'
              />

            </>

            {this.state.pimsIntegrationStatus && (
              <>
                <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1em' }}>PIMS Integration</Typography>

                <LineItem
                  value={
                    this.state.pimsIntegrationStatus.target
                    + (this.state.pimsIntegrationStatus.subTarget ? ` (${this.state.pimsIntegrationStatus.subTarget})` : '')
                  }
                  description='Target'
                  style={{ marginTop: '0' }}
                />

                {this.state.pimsIntegrationStatus.linkingStatus !== 'Done' && (
                  <LineItem
                    value={this.state.pimsIntegrationStatus.linkingStatus}
                    description='Linking Status'
                  />
                )}

                {this.state.pimsIntegrationStatus.pimsIntegratorId === 1 &&
                <LineItem
                  value={this.state.pimsIntegrationStatus.lastSyncedFromTarget
                    ? DateTime.fromISO(this.state.pimsIntegrationStatus.lastSyncedFromTarget).toLocaleString(DateTime.DATETIME_SHORT)
                    : 'Never'
                  }
                  description='Target Last Synced'
                />}

                {this.state.pimsIntegrationStatus.subTarget && (
                  <LineItem
                    value={this.state.pimsIntegrationStatus.lastSyncedFromSubTarget
                      ? DateTime.fromISO(this.state.pimsIntegrationStatus.lastSyncedFromSubTarget).toLocaleString(DateTime.DATETIME_SHORT)
                      : 'Never'
                    }
                    description='Target Data Last Updated'
                  />
                )}

                {this.state.pimsIntegrationStatus.failedSyncs > 0 && (
                  <div style={{ color: 'red', backgroundColor: 'rgba(255, 0, 0, 0.1)',padding: '5px',borderRadius: '5px' }}>
                    <LineItem
                      value={this.state.pimsIntegrationStatus.failedSyncs}
                      description='Failed Syncs'
                      style={{ margin: '0' }}
                    />
                    <p style={{ margin: '5px 0', fontWeight: 'lighter' }}>Resets after a successful sync</p>
                  </div>
                )}
              </>
            )}

          </>
        )}
      </Container>
    );
  }
}

Practice.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Practice);
