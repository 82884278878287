import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { TextField, Box, CircularProgress, Container, Grid, Typography, LinearProgress } from '@mui/material';
import Cookies from 'js-cookie';
import NotifySound from '../../../../assets/sounds/notify.wav';
const COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME;

import { Button } from '@lexcelon/react-util';

import { setError } from '../../../../alerts';
import { confirm, stopConfirmLoading, startConfirmLoading, closeConfirm } from '../../../../alerts/confirm';

import { getTest, WEBSOCKET_BASE_URL, createTest, createEllieTest } from '../../../../api';
let reconnectRetries = 0;
const WEBSOCKET_TIMEOUT = 5 * 60e3;

class TestInProgress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      test: null,
      inputText: '',
      // title: 'Taking High-Res Image for Analysis',
      // subtitle: 'This is the subtitle',
      // allowCancelTest: true,
      // loadingProgress: 50,
      // isError: false,
      // isComplete: false,
      // flashing: false,
      // imageUrl: 'https://parasight-public-assets.s3.us-east-2.amazonaws.com/test-gifs/wash.gif',
      // description: 'This test will automatically continue once the egg chamber is removed. If the egg chamber present is already new, press the spacebar or the button below to continue.',
      // actions: [
      //   {
      //     title: 'Egg Chamber is New',
      //     command: 'test',
      //     textInput: 'Barcode Value'
      //   }
      // ],
      title: null,
      boldTitle: false,
      subtitle: null,
      allowCancelTest: false,
      loadingProgress: null,
      isError: false,
      isFatalError: false,
      isComplete: false,
      imageUrl: null,
      description: null,
      actions: null,
      flashing: false,
      redirectToTestId: null,
      isLoadingActionIndex: null,
      flashCounter: 0
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match?.params?.id !== prevProps.match?.params?.id) {
      this.socket?.close();
      this.componentDidMount();
    }

    if (JSON.stringify(this.state.actions) !== JSON.stringify(prevState.actions) || JSON.stringify(this.state.title) !== JSON.stringify(prevState.title)) {
      this.setState({ isLoadingActionIndex: null });
    }
  }

  componentDidMount() {
    let testId = this.props.match?.params?.id;
    getTest(testId).then(test => {
      this.setState({ test });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve test. Please try again.');
      this.setState({ redirect: true });
    });

    // Set a timer for every second that increases flashCounter
    this.flashTimer = setInterval(() => {
      this.setState({ flashCounter: this.state.flashCounter + 0.5 });
      if (this.state.flashing && this.state.flashCounter % 30 === 15) {
        const audio = new Audio(NotifySound);
        audio.play();
      }
    }, 500);

    // See note below about why this if statement is here
    if (process.env.NODE_ENV !== 'development' || (process.env.NODE_ENV === 'development' && this.hasMountedAlready === true /* see note below */)) {
      // Get the cookie manually so the websocket can check for authentication
      let jwtToken = Cookies.get(COOKIE_NAME);
      this.socket = new WebSocket(`${WEBSOCKET_BASE_URL}?token=${jwtToken}`);

      // Open the connection with the portal server websocket
      this.socket.addEventListener('open', () => {
      });

      // Initial timeout
      let timeoutTimerId = null;
      let pingIntervalId = null;
      let lastNonPingMessage = 0;

      const setPingInterval = () => {
        pingIntervalId = setInterval(() => {
          // console.log('Sending ping');
          this.socket.send('ping');
        }, 5e3);
      };

      const resetWebsocketTimeout = ()=> {
        // console.log('Reseting timeout');
        clearTimeout(timeoutTimerId);
        timeoutTimerId = setTimeout(()=>{
          this.socket.close(4600, 'Client timed out');
        }, WEBSOCKET_TIMEOUT);
      };

      resetWebsocketTimeout();

      this.socket.addEventListener('message', (eventMessage) => {
        if (eventMessage.data === 'AUTHENTICATED') {
          // Wait for AUTHENTICATED message (sent when we are successfully connected to the instrument)
          this.socket.send(JSON.stringify({
            command: 'START_TEST',
            testId: testId
          }));

          // :: pong handler
          this.socket.addEventListener('message', ({ data, timeStamp }) => {
            // We log the timestamp of any non-pong message
            if ( data != 'pong') {
              // timeStamp is in milliseconds since the epoch
              if (lastNonPingMessage == timeStamp) return;
              else if (lastNonPingMessage < timeStamp) {
                lastNonPingMessage = timeStamp;
                resetWebsocketTimeout();
              }
              // This should never happen, but sanity check
              else if (lastNonPingMessage > timeStamp) {
                console.log('???');
              }

            }

            else {
              // console.log('pong', data, 'last message:', new Date(lastNonPingMessage));
            }
          });

          // If we get a message back, we've connected to the instrument server, and set the ping interval
          this.socket.addEventListener('message', setPingInterval, { once: true });

          // :: normal message handler
          this.socket.addEventListener('message', (incomingMessage)=> {
            if (incomingMessage.data == 'pong') return;
            try {
              const displayData = JSON.parse(incomingMessage.data);

              if (displayData.isFatalError && this.socket.readyState === this.socket.OPEN) {
                this.socket.close(4005, displayData.subtitle ?? 'Potential mechanical issue.');
              }

              this.setState({
                title: displayData.title,
                boldTitle: displayData.boldTitle ?? false,
                subtitle: displayData.subtitle,
                allowCancelTest: displayData.allowCancelTest,
                loadingProgress: displayData.loadingProgress,
                isError: displayData.isError,
                isFatalError: displayData.isFatalError ?? false,
                isComplete: displayData.isComplete,
                imageUrl: displayData.imageUrl,
                imageBackgroundColor: displayData.imageBackgroundColor,
                description: displayData.description,
                actions: displayData.actions,
                flashing: displayData.flashing ?? false,
                flashCounter: 0,
                inputText: ''
              });
            }
            catch (e) {
              console.log(e);
            }
          });
        }
        else {
          // Do error handling
        }
      }, { once: true });


      this.socket.addEventListener('close', ({ code, reason }) => {
        clearInterval(pingIntervalId);
        clearTimeout(timeoutTimerId);

        // We'll use code 4555 as an 'OK to immediately retry' code
        if (code == 4_555 && reconnectRetries < 3) {
          reconnectRetries++;
          return this.componentDidMount();
        }

        // If there's a mechanical issue or other fatal error, ignore
        if (code == 4005) return;

        let description = 'Uh oh! We were unexpectedly disconnected from the instrument. Please restart test.';

        switch (code) {
          case 4006:
            reason = 'Timeout';
            description = 'Instrument stopped responding. Check network connection.';
            break;
          case 4508:
            description = '';
            break;
          case 4600:
            reason = 'Instrument was idle for too long';
            description = 'Make sure the instrument is connected to the internet.';
            break;
        }
        reconnectRetries = 0;
        this.setState({
          title: reason || 'Unexpectedly Disconnected',
          boldTitle: false,
          subtitle: null,
          allowCancelTest: true,
          loadingProgress: null,
          isError: true,
          isComplete: false,
          imageUrl: null,
          description,
          flashing: false,
          actions: [
            {
              command: 'START_TEST',
              title: 'Restart Test'
            }
          ],
        });
      });
    }

    /*
     * In dev mode, React.StrictMode renders everything twice, which is a huge problem when
     * we're talking about a connection to the instrument. To accommodate that without having
     * to get rid of React.StrictMode altogether (it is not granular), we check in development
     * mode to see if this is the second render before connecting to the websockets
    */
    this.hasMountedAlready = true;
  }

  componentWillUnmount() {
    // Close the socket?
    this.socket?.close();
    clearInterval(this.flashTimer);
  }

  onAction = (action, index) => {
    this.setState({ isLoadingActionIndex: index });

    // Send this command to the servers
    if (this.socket != null) {

      if (action.command === 'START_TEST') {
        const { test } = this.state;
        // Duplicate the current test and redirect to its page
        if (test == null) {
          setError('Error: Test does not exist.');
          this.setState({ redirect: true });
          return;
        }

        if (test.getEllieOrderTestTypeId() != null) {
          createEllieTest({
            labdaqAccessionId: test.getEllieOrderTestType()?.getEllieOrder()?.getLabdaqAccessionId(),
            sampleQualityDescriptor: test.getSampleQualityDescriptor(),
            instrumentSerialNumber: test.getInstrumentSerialNumber()
          }).then(newTest => {
            this.setState({ redirectToTestId: newTest.getId() });
          }).catch(error => {
            setError(error ?? 'Error: Unable to create new test.');
            this.setState({ redirect: true });
          });
        }
        else {
          createTest({
            veterinarianId: test.getVeterinarianId(),
            labTechId: test.getLabTechId(),
            animalId: test.getAnimalId(),
            testTypeId: test.getTestTypeId(),
            instrumentSerialNumber: test.getInstrumentSerialNumber(),
            animalAge: test.getAnimalAge(),
            sampleName: test.getSampleName(),
            comments: test.getComments()
          }).then(newTest => {
            this.setState({ redirectToTestId: newTest.getId() });
          }).catch(error => {
            setError(error ?? 'Error: Unable to create new test.');
            this.setState({ redirect: true });
          });
        }
      }
      else {
        if (action.textInput) {
          if (this.state.inputText == null || this.state.inputText.length < 7) {
            setError('Error: Please enter or scan a value.');
            this.setState({ isLoadingActionIndex: null });
            return;
          }
          this.socket.send(JSON.stringify({ command: action.command, data: this.state.inputText }));
        }
        else this.socket.send(JSON.stringify({ command: action.command }));
      }
    }
  }

  openCancelTestDialogue = () => {
    confirm({
      title: 'Are you sure you want to cancel your test?',
      body: 'Your results will be voided.',
      onConfirm: () => {
        startConfirmLoading();
        if (this.socket != null && this.socket.readyState == this.socket.OPEN) {
          this.socket.send(JSON.stringify({ command: 'CANCEL_TEST' }));
          stopConfirmLoading();
          closeConfirm();
        }
        else {
          stopConfirmLoading();
          closeConfirm();
          setError('Error: Something went wrong.');
          this.setState({ redirect: true });
        }
      },
      danger: true
    });
  }

  render() {
    if (this.state.redirectToTestId != null) return <Redirect to={`/tests/${this.state.redirectToTestId}`} />;
    else if (this.state.test != null && ((this.state.test?.getStatus() !== 'CREATED' && this.state.test?.getStatus() !== 'IN_PROGRESS') || this.state.isComplete === true)) return <Redirect to={`/tests/${this.props.match?.params?.id}`} />;
    else if (this.state.redirect) return <Redirect to='/tests' />;
    return (
      <div style={{ flex: 1, width: '100%', minHeight: 'calc(100vh - 65px)', backgroundColor: this.state.flashing && this.state.flashCounter > 15 && (this.state.flashCounter < 3 * 60 ? (this.state.flashCounter % 2 === 1) : (this.state.flashCounter === Math.floor(this.state.flashCounter))) ? `rgba(255,${this.state.flashCounter < 3 * 60 ? 200 : 100},30,0.5)` : undefined }}>
        <Container sx={{ paddingTop: '10px', paddingBottom: '30px' }}>
          <div style={{ minHeight: '16vh' }}>
            {/* Loading Icon */}
            {this.state.title == null &&
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress style={{ marginRight: '10px' }} />
              <Typography variant='h1' style={{ color: 'slategrey', fontSize: '25px', fontWeight: 'bold' }}>Waiting for server...</Typography>
            </div>}

            {/* Title & Subtitle */}
            {this.state.title != null && <Typography variant='h1' sx={{ textAlign: 'center', marginBottom: this.state.subtitle != null ? '0.2em' : '1em', color: this.state.isError ? 'red' : 'black', fontSize: { xs: '32px', md: '45px', lg: '55px' }, fontWeight: this.state.boldTitle ? 'bold' : undefined }}>{this.state.title ?? 'Connecting to Server'}</Typography>}
            {this.state.subtitle != null && <Typography variant='subtitle1' sx={{ color: 'slategrey', textAlign: 'center', fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold', marginBottom: '1em' }}>{this.state.subtitle}</Typography>}
          </div>

          {/* Image & Description */}
          <div style={{ minHeight: '35vh', maxHeight: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            {this.state.imageUrl != null &&
            <Box sx={{ width: { xs: '100%', sm: '75%', md: '50%' }, height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '3em' }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden', // in case image overflows vertically
                }}
              >
                <img
                  src={this.state.imageUrl}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    backgroundColor: this.state.imageBackgroundColor ? `#${this.state.imageBackgroundColor}` : 'transparent',
                  }}
                  alt={this.state.title}
                />
              </div>
            </Box>}

            {this.state.description != null &&
            <div style={{ height: this.state.imageUrl == null ? '30vh' : 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <Typography variant='body1' sx={{ fontSize: { xs: '20px', sm: '25px' }, textAlign: 'center', fontWeight: 'bold', color: 'slategrey', whiteSpace: 'pre-line' }}>{this.state.description}</Typography>
            </div>}
          </div>

          {/* Buttons */}
          <div style={{ minHeight: '4em', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            {this.state.actions?.length > 0 &&
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
              {this.state.actions.map((action, index) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                  {action.textInput &&
                  <TextField
                    required
                    label={action.textInput}
                    value={this.state.inputText}
                    onChange={e => this.setState({ inputText: e.target.value })}
                    variant='filled'
                    disabled={this.state.isLoadingActionIndex != null}
                    style={{ width: '100%', marginBottom: '0.5em' }}
                    inputProps={{ maxLength: 20 }}
                  />}
                  <Button style={{ height: '40px', marginLeft: '0.5em', marginRight: '0.5em', minWidth: '200px' }} onClick={() => this.onAction(action, index)} isLoading={this.state.isLoadingActionIndex === index} disabled={this.state.isLoadingActionIndex != null}>
                    {action.title}
                  </Button>
                </div>
              ))}
            </div>}
          </div>

          {/* Loading Bar & Cancel Button */}
          {(this.state.loadingProgress != null || this.state.allowCancelTest) &&
          <Grid container justifyContent={{ xs: 'center', md: this.state.allowCancelTest ? 'flex-end' : 'center' }} columnSpacing={1} rowSpacing={1} alignItems='center' direction='row' style={{ marginBottom: '10px', marginTop: this.state.loadingProgress == null ? '70px' : '0px' }}>

            {this.state.loadingProgress != null &&
            <Grid item xs={12} sm={12} md={10}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant='body2' style={{ fontWeight: 'bold', fontSize: '20px', color: 'slategrey', textAlign: 'center', marginRight: '5px' }}>{`${this.state.loadingProgress}%`}</Typography>
                <LinearProgress variant='determinate' value={this.state.loadingProgress} style={{ height: '20px', borderRadius: '10px', width: '100%' }} />
              </div>
            </Grid>}

            {this.state.allowCancelTest &&
            <Grid item xs={12} sm={5} md={2}>
              <Button onClick={this.openCancelTestDialogue} style={{ height: '30px', width: '100%' }} danger>
                Cancel Test
              </Button>
            </Grid>}
          </Grid>}

        </Container>
      </div>
    );
  }
}

TestInProgress.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(TestInProgress);
